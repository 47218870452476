<template>
  <el-dialog
    class="my-dialog1"
    title="选择产品类型"
    width="600px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    center
  >
    <div class="dialog-list">
      <div class="title-search my-table-list">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <div class="title-search-line">
            <el-form-item label="产品名称:">
              <el-input size="small" v-model="searchForm.productTypeName" placeholder="请输入产品名称" clearable></el-input>
            </el-form-item>
            <el-button type="primary" size="small" @click="getData(true)">查询</el-button>
          </div>
        </el-form>
      </div>
      <div class="tree-list" v-loading="loading"  
      :element-loading-text="loadtext" >
        <el-tree
          :data="treeData"
          :props="treeprops"
          node-key="id"
          :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span :class="`name-color-${data.color} tree-label`" @click="() => expandTree(node)">{{ data.name }}</span>
            <span v-if="!data.childrenList || !data.childrenList.length">
              <el-button
                type="text"
                size="middle"
                @click="() => chooseData(data)">
                选择
              </el-button>
            </span>
          </span>
        </el-tree>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
export default {
  name: "productType",
  components: {
  },
  props: {},
  data() {
    return {   
      searchForm: {
        productTypeName: ''
      },
      treeData: [],
      treeprops:{
        children: 'childrenList'
      },
      loadtext: '加载中',
      closeFlag: false,
      loading: false
    }
  },
  created() {
    this.getData()
	},
  methods: {
    getData(){
      let _this = this
      this.loading = true
      request.commonproducttypetree({
          productTypeName: _this.searchForm.productTypeName,
          productTypeAlias: ''
      }).then(res => {
        if(res.code == 200){
          if(res.data && res.data.length){
            _this.handleData(res.data)
          }else{
            his.treeData = data
          }
        }else{
          _this.message(res.msg || '', "error")
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
        console.log(e)
      })
    },
    handleData(data){
      function itHandleData(item){
        for(let i = 0; i < item.length; i++){
          let cur = item[i]
            let color = ''
            if(cur.conventional == '01' && cur.prefabricated == '01'){
              color = 'blue1'
            }else if(cur.prefabricated == '01') {
              color = 'red'
            }else if(cur.conventional == '01') {
              color = 'blue'
            }
            cur.id = cur.productTypeId
            cur.name = `（${cur.productTypeCode}）${cur.productTypeName}`
            cur.color = color
          if(cur.childrenList && cur.childrenList.length){
            itHandleData(cur.childrenList)
          }
        }
      }

      itHandleData(data)
      this.treeData = data

    },
    expandTree(node){
      node.expanded = !node.expanded
    },
    chooseData(data){
      this.$emit('getproducttype', data)
      this.closeModel()
    },
    initData(){
      this.closeFlag = true
    },
    closeModel() {
      this.closeFlag = false
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
  .my-upload ::v-deep .el-upload--picture-card{
    width: 88px;
    height: 88px;
    line-height: 98px;
  }

  .my-dialog1 ::v-deep .el-dialog--center .el-dialog__body{
    padding: 5px 25px 30px;
  }

  .my-dialog1 ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  
  .title-search{
    background-color: #fff;
    box-shadow: none;
  }
  .tree-list{
    overflow: auto;
    max-height: 65vh;
    min-height: 30vh;
    padding-right: 10%;
    margin-top: 10px;
  }
  .custom-tree-node{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    cursor: default;
  }
  .tree-label{
    cursor: pointer;
  }

  .name-color-blue{
    color: #00BCD4;
  }

  .name-color-red{
    color: #e47958;
  }

  .name-color-blue1{
    color: #0079FE;
  }
</style>


