<template>
  <div class="page-contain my-table-list">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <div class="page-table">
      <div class="title-search pb20">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <div class="title-search-line">
            <el-form-item label="产品种类:">
              <el-input size="small" :disabled="true" class="my-input-btn" v-model="searchForm.productTypeName" placeholder="请选择产品种类">
                <el-button slot="append" @click="chooseType" type="primary">选择</el-button>
              </el-input>
            </el-form-item>
            <el-form-item label="产品名称:">
              <el-input size="small" v-model="searchForm.productName" placeholder="请输入产品名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="开具时间:">
              <el-date-picker
                class="my-date-range"
                v-model="timeRange"
                @change="timeChange"
                type="daterange"
                align="right"
                unlink-panels
                size="small"
                range-separator="到"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="打印和激活:">
              <el-select size="small" v-model="searchForm.certificateOfComplianceType" clearable placeholder="请选择">
                <el-option label="全部" value=""></el-option>
                <el-option label="打印" value="01"></el-option>
                <el-option label="激活" value="02"></el-option>
              </el-select>
            </el-form-item>
            <el-button type="primary" size="small" @click="getData(true)">查询</el-button>
          </div>
        </el-form>
      </div>
      <el-table 
        ref="table" 
        class="my-table"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        :height="tableHeight"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <!-- <el-table-column align="center" type="selection" width="60">
        </el-table-column> -->
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="操作" fixed="right" width="200">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="view(scope.row, scope.$index)">查看</el-button>
            <el-button size="small" type="text" @click="edit(scope.row, scope.$index)">修改</el-button>
            <el-button size="small" type="text" @click="del(scope.row, scope.$index)">删除</el-button>
            <el-button size="small" type="text" @click="print(scope.row, scope.$index)">打印合格证</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页组件 -->
      <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
    </div>
    <productType ref="getproducttype" @getproducttype="getproducttype"></productType>
  </div>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  import productType from './../common/productType.vue'
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  export default {
    name: 'usestatistic',
    components: {
      pagination,
      breadcrumb,
      productType
    },
    data() {
      let title = '合格证使用统计'
      return {
        timeRange: '',
        pickerOptions: {
          shortcuts: [
          {
            text: '最近一月',
            onClick(picker) {
              let obj = utils.getDays('1')
              picker.$emit('pick', [obj.start, obj.end])
            }
          }, {
            text: '最近一季',
            onClick(picker) {
              let obj = utils.getDays('3')
              picker.$emit('pick', [obj.start, obj.end])
            }
          }, {
            text: '最近一年',
            onClick(picker) {
              let obj = utils.getDays('12')
              picker.$emit('pick', [obj.start, obj.end])
            }
          }]
        },
        breadStaticData:['承诺达标合格证管理', title],
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          bodyId: utils.getBodyId(),
          productName: '',
          productTypeId: '',
          productTypeName: '',
          certificateOfComplianceType: '',
          recentlyOneMonth: false,
          recentlyOneQuarter: false,
          recentlyOneYear: false,
          startTime: '',
          endTime: ''
        },
        urlName: 'commoncphgzgetHGZUseStatistics',
        tableHead: [{
          prop: 'productName',
          label: '产品名称',
        },{
          prop: 'printNum',
          label: '打印数量',
          width: 180
        },{
          prop: 'activateNum',
          label: '激活数量',
          width: 180
        },{
          prop: 'ewmNum',
          label: '合格证扫码数量',
          width: 180
        }],
        loadtext: '加载中',
        pageName: title,
        tableName: `${title}列表`,
        linkData:[],
        thirdStaticData:[],
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        }
      }
    },
    created() {
      this.getData()
    },
    mounted(){
      this.getTopHeight()
    },
    methods: {
      chooseType(){
        this.$refs.getproducttype.initData()
      },
      getproducttype(data){
        console.log(data)
        this.searchForm.productTypeName = data.productTypeName || ''
        this.searchForm.productTypeId = data.productTypeId || ''
      },
      timeChange(val){
        let startTime = '',
          endTime = ''
        if(val){
          startTime = utils.dateFormat(val[0], 'yyyy-MM-dd')
          endTime = utils.dateFormat(val[1], 'yyyy-MM-dd')
        }
        this.searchForm.startTime = startTime
        this.searchForm.endTime = endTime
      },
      view(data, index){

      },
      edit(data, index){

      },
      del(data, index){

      },
      print(data, index){

      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            _this.listData = lists
            lists = lists.map(item => {
              return {
                ...item
              }
            })
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      }
    },
  }
</script>
<style lang="scss" scoped>
  
</style>